import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { SupplierRoutingModule } from './supplier-routes.module';

// module components
import { SupplierContentComponent } from './content/supplier-content.component';
import { SupplierHomeComponent } from './home/supplier-home.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportComponent } from './reports/report.component';
import { ReportEditorComponent } from './reports/report-editor.component';
import { SupplierUsersComponent } from './users/supplier-users.component';
import { SupplierUserComponent } from './users/supplier-user.component';
import { SupplierClocksComponent } from './clocks/supplier-clocks.component';


// shared modules
import { SharedModule } from '../shared/shared.module';
import { SupplierReportService } from './shared/services/report.service';
import { ProductService } from './shared/services/product.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DxReportViewerModule } from 'devexpress-reporting-angular';
import { SupplierUserService } from './shared/services/supplier-user.service';
import { SupplierService } from './shared/services/supplier.service';
import { AuctionClusterService } from './shared/services/auction-cluster.service';
import { AuctionService } from './shared/services/auction.service';
import { CatalogService } from './shared/services/catalog.service';
import { LocationService } from './shared/services/location.service';
import { MasterDataService } from './shared/services/master-data.service';
import { LookupTableService } from './shared/services/lookup-table.service';
import { DxBoxModule, DxListModule, DxTagBoxModule, DxScrollViewModule, DxToastModule, DxPopupModule, DxValidationGroupModule, DxFormModule, DxValidationSummaryModule, DxValidatorModule, DxTextBoxModule, DxTabPanelModule, DxTabsModule, DxCheckBoxModule, DxSelectBoxModule, DxButtonModule, DxBulletModule, DxTemplateModule, DxColorBoxModule, DxDataGridModule, DevExtremeModule, DxDateBoxModule } from 'devextreme-angular';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    SupplierRoutingModule,
    HttpClientModule,
    TranslateModule,
    SharedModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DxReportViewerModule,
    DxColorBoxModule,
    DxDataGridModule,
    DxTemplateModule,
    DxBulletModule,
    DxButtonModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxTabsModule,
    DxTabPanelModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxFormModule,
    DxValidationGroupModule,
    DxPopupModule,
    DxToastModule,
    DxScrollViewModule,
    DxTagBoxModule,
    DxListModule,
    DxBoxModule,
    DevExtremeModule,
    DxDateBoxModule,
    PdfJsViewerModule
  ],
  declarations: [
    SupplierContentComponent,
    SupplierHomeComponent,
    ReportsComponent,
    ReportComponent,
    ReportEditorComponent,
    SupplierUsersComponent, SupplierUserComponent,
    SupplierClocksComponent
  ],
  providers: [
    SupplierReportService,
    SupplierUserService,
    SupplierService,
    ProductService,
    AuctionClusterService,
    AuctionService,
    CatalogService,
    LocationService,
    MasterDataService,
    LookupTableService
  ],
  bootstrap: []
})
export class SupplierModule { }
