<div class="report-editor-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="clr-row" style="width: 100%">
    <dx-report-viewer *ngIf="!product?.reportingPDFOnly" #reportViewer [reportUrl]="reportUrl" height="100%" class="clr-col-sm-9">
      <dxrv-request-options [invokeAction]="invokeAction" [host]="hostUrl"></dxrv-request-options>
      <dxrv-export-settings [showPrintNotificationDialog]="false"></dxrv-export-settings>
      <dxrv-callbacks (StartBuild)="reportStartBuild($event)"
                      (DocumentReady)="reportDocumentReady($event)"
                      (OnServerError)="reportOnServerError($event)"
                      (CustomizeMenuActions)="reportCustomizeMenuActions($event)"
                      (CustomizeElements)="reportCustomizeElements($event)">
      </dxrv-callbacks>
    </dx-report-viewer>
    <ng2-pdfjs-viewer *ngIf="product?.reportingPDFOnly" #pdfViewer class="clr-col-sm-9" height="100%"></ng2-pdfjs-viewer>

    <div class="card form clr-col-sm-3" *ngIf="filters.length > 0">
      <div class="card-header">
        {{ reportName | translateJson}}
      </div>
      <div class="card-block">
        <div *ngFor="let filter of filters; let i = index" class="filter-column">
          <!-- Date -->
          <div *ngIf="filter.propertyTypeId == 4 && !isProductPropertyWithStoredProcedure(filter.productPropertyId)">
            <label [ngClass]="{'required': filter.filterRequired}">{{ getTranslation(filter.name) }}</label>
            <dx-select-box name="filter.{{filter.name}}"
                           [items]="getFilterItems(filter.productPropertyId)"
                           valueExpr="value"
                           [value]="getDateValue(filter.productPropertyId)"
                           displayExpr="name"
                           [rtlEnabled]="rtlEnabled"
                           [searchEnabled]="true"
                           [showClearButton]="true"
                           placeholder="{{ 'SHARED.SELECT' | translate }} ..."
                           cssClass="filter-lot-select"
                           (onInitialized)="hasIncompleteRequiredFilter();"
                           (onValueChanged)="clearDatePeriod(filter.productPropertyId); setValue(filter.productPropertyId, $event); onPropertyChanged(filter, $event); hasIncompleteRequiredFilter();">
            </dx-select-box>
            <div class="date-range-holder" *ngIf="dateFiltersCascadingDefinitions[filter.productPropertyId] == '3'">
              <dx-date-box type="date"
                           displayFormat="shortdate"
                           openOnFieldClick="true"
                           [value]="dateValue1(filter.productPropertyId)"
                           (onInitialized)="hasIncompleteRequiredFilter();"
                           (onValueChanged)="handleDate1ValueChange(filter.productPropertyId, $event); onPropertyChanged(filter, $event); hasIncompleteRequiredFilter();">
              </dx-date-box>
              <dx-date-box type="date"
                           openOnFieldClick="true"
                           [disabled]="isDisabled(filter.productPropertyId)"
                           displayFormat="shortdate"
                           [min]="getMin(filter.productPropertyId)"
                           [value]="dateValue2(filter.productPropertyId)"
                           (onInitialized)="hasIncompleteRequiredFilter();"
                           (onValueChanged)="handleDate2ValueChange(filter.productPropertyId, $event); onPropertyChanged(filter, $event); hasIncompleteRequiredFilter();">
              </dx-date-box>
            </div>
            <div class="date-range-holder" *ngIf="dateFiltersCascadingDefinitions[filter.productPropertyId] == '5'">
              <dx-date-box type="date"
                           openOnFieldClick="true"
                           [value]="dateValue(filter.productPropertyId)"
                           (onInitialized)="hasIncompleteRequiredFilter();"
                           (onValueChanged)="handleDateValueChange(filter.productPropertyId, $event); onPropertyChanged(filter, $event); hasIncompleteRequiredFilter();">
              </dx-date-box>
            </div>
          </div>
          <!-- Date with stored procedure -->
          <div *ngIf="filter.propertyTypeId == 4 && isProductPropertyWithStoredProcedure(filter.productPropertyId)">
            <label [ngClass]="{'required': filter.filterRequired}">{{ getTranslation(filter.name) }}</label>
            <dx-date-box type="date"
                         openOnFieldClick="true"
                         displayFormat="shortdate"
                         [disabledDates]="productPropertyDropdowns[i]"
                         [min]="firstEnabledDate[i]"
                         [max]="lastEnabledDate[i]"
                         [(value)]="filterBindings[filter.productPropertyId]"
                         (onInitialized)="hasIncompleteRequiredFilter();"
                         (onValueChanged)="handleDateValueChangeStoredProcedure(filter.productPropertyId, $event); onPropertyChanged(filter, $event); hasIncompleteRequiredFilter();">
            </dx-date-box>
          </div>
          <!-- Master data value -->
          <div *ngIf="filter.propertyTypeId == 5">
            <label [ngClass]="{'required': filter.filterRequired}">
              {{ getTranslation(filter.name) }}
            </label>

            <div *ngIf="!filter.multiSelect">
              <masterdata-dropdown name="product{{i}}"
                                   [auctionClusterId]="auctionClusterId"
                                   [(selectedRowId)]="filterBindings[filter.productPropertyId]"
                                   [listId]="filter.masterDataListId"
                                   [filteredKeys]="filter.filteredKeys"
                                   [fieldId]="filter.masterDataListFieldId"
                                   (onValueChanged)="onPropertyChanged(filter, $event); hasIncompleteRequiredFilter();"
                                   (onInitialized)="registerDropDown($event, filter.productPropertyId);hasIncompleteRequiredFilter();">
              </masterdata-dropdown>
            </div>

            <div *ngIf="filter.multiSelect">
              <masterdata-tagbox name="product{{i}}"
                                 [auctionClusterId]="auctionClusterId"
                                 [listId]="filter.masterDataListId"
                                 [(selectedRowIds)]="filterBindings[filter.productPropertyId]"
                                 [filteredKeys]="filter.filteredKeys"
                                 [fieldId]="filter.masterDataListFieldId"
                                 (onInitialized)="hasIncompleteRequiredFilter();"
                                 (onValueChanged)="onPropertyChanged(filter, $event); hasIncompleteRequiredFilter();">
              </masterdata-tagbox>
            </div>
          </div>
          <!--- Text-->
          <div *ngIf="filter.propertyTypeId == 3 && !isProductPropertyWithStoredProcedure(filter.productPropertyId)">
            <label [ngClass]="{'required': filter.filterRequired}">{{ getTranslation(filter.name) }}</label><br />
            <dx-text-box [(value)]="filterBindings[filter.productPropertyId]" name="{{'product' + i}}" (onInitialized)="hasIncompleteRequiredFilter();" (onValueChanged)="onPropertyChanged(filter, $event); hasIncompleteRequiredFilter();"></dx-text-box>
          </div>
          <!--- Text with stored procedure-->
          <div *ngIf="filter.propertyTypeId == 3 && isProductPropertyWithStoredProcedure(filter.productPropertyId)">
            <label [ngClass]="{'required': filter.filterRequired}">{{ getTranslation(filter.name) }}</label><br />
            <dx-select-box name="product{{i}}"
                           [items]="productPropertyDropdowns[i]"
                           [(value)]="filterBindings[filter.productPropertyId]"
                           [rtlEnabled]="rtlEnabled"
                           [searchEnabled]="true"
                           [showClearButton]="true"
                           placeholder="{{ 'SHARED.SELECT' | translate }} ..."
                           (onInitialized)="hasIncompleteRequiredFilter();"
                           (onValueChanged)="clearLotPropertyValue(filter.productPropertyId, $event); onPropertyChanged(filter, $event); hasIncompleteRequiredFilter();"
                           cssClass="filter-lot-select"
                           (onInitialized)="registerDropDown($event, filter.productPropertyId)">
            </dx-select-box>
          </div>
          <!--- Number -->
          <div *ngIf="filter.propertyTypeId == 1 && !isProductPropertyWithStoredProcedure(filter.productPropertyId)">
            <label [ngClass]="{'required': filter.filterRequired}">{{ getTranslation(filter.name) }}</label><br />
            <dx-number-box name="{{'product' + i}}"
                           [(value)]="filterBindings[filter.productPropertyId]"
                           [showSpinButtons]="true"
                           format="#0"
                           [step]="1"
                           class="focusable"
                           [rtlEnabled]="rtlEnabled"
                           [showClearButton]="true"
                           (onInitialized)="hasIncompleteRequiredFilter();"
                           (onValueChanged)="onPropertyChanged(filter, $event); hasIncompleteRequiredFilter();"
                           #numberValueProp>
            </dx-number-box>
          </div>
          <!--- Number with stored procedure -->
          <div *ngIf="filter.propertyTypeId == 1 && isProductPropertyWithStoredProcedure(filter.productPropertyId)">
            <label [ngClass]="{'required': filter.filterRequired}">{{ getTranslation(filter.name) }}</label><br />
            <dx-select-box name="product{{i}}"
                           [items]="productPropertyDropdowns[i]"
                           [(value)]="filterBindings[filter.productPropertyId]"
                           [rtlEnabled]="rtlEnabled"
                           [searchEnabled]="true"
                           [showClearButton]="true"
                           placeholder="{{ 'SHARED.SELECT' | translate }} ..."
                           (onInitialized)="hasIncompleteRequiredFilter();"
                           (onValueChanged)="clearLotPropertyValue(filter.productPropertyId, $event); onPropertyChanged(filter, $event); hasIncompleteRequiredFilter();"
                           cssClass="filter-lot-select"
                           (onInitialized)="registerDropDown($event, filter.productPropertyId)">
            </dx-select-box>
          </div>
        </div>

        <div>
          <div class="filter-template-column" *ngIf="!report.systemDefault">
            <button type="button" class="btn btn-icon btn-primary" (click)="save()" [disabled]="disableButton()">
              <i class="dx-icon-floppy"></i>{{ 'SHARED.SAVE' | translate }}
            </button>
          </div>
          <div class="filter-template-column">
            <button type="button" class="btn btn-icon btn-primary" (click)="openSaveAsNewUserReportTemplateDialog()" [disabled]="disableButton()">
              <i class="dx-icon-plus"></i>{{ 'SHARED.SAVE_AS' | translate }}
            </button>
          </div>
          <div class="filter-template-column">
            <button type="button" class="btn btn-icon btn-primary" (click)="filterReport()" [disabled]="disableButton()">
              <i class="dx-icon-detailslayout"></i>{{ 'SHARED.RUN' | translate }}
            </button>
          </div>
          <div class="filter-template-column" *ngIf="report.reportDesign.exportType > 0">
            <button type="button" class="btn btn-icon btn-primary" (click)="exportReport()" [disabled]="disableButton()">
              <i class="dx-icon-export"></i>{{ 'SHARED.EXPORT' | translate }}
            </button>
          </div>
          <div class="filter-template-column">
            <span *ngIf="lastRefresh !== ''">
              {{ 'SUPPLY_MATRIX.LAST_REFRESH' | translate }} {{ lastRefresh }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <dx-popup
  #saveReportingPopupForm
  width="600"
  height="250"
  [showTitle]="true"
  title="{{ 'REPORTING.USER_REPORT_TEMPLATE.TITLE' | translate }}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isSaveProfileDialogOpened">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-form #saveReportingForm
                 id="saveReportingForm"
                 [showValidationSummary]="false"
                 colCount="auto"
                 [minColWidth]="500">
          <dxi-item label="{{ 'SHARED.NAME' | translate }}" dataField="newReportName">
            <div *dxTemplate>
              <dx-text-box [(value)]="newReportName"></dx-text-box>
            </div>
            <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
          </dxi-item>
        </dx-form>
        <br /><br />
        <button type="submit" class="btn btn-primary" (click)="saveNewReport();" name="btnBuyerSaveTemplateSubmit" [disabled]="isDisabledButton()">
          {{ 'SHARED.SAVE' | translate }}
        </button>
        <button type="button" class="btn btn-outline" (click)="closeSaveAsNewUserReportTemplateDialog();">{{ 'SHARED.CANCEL' | translate }}</button>
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>
