import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { LanguageService } from '../../../shared/services/language.service';
import { CoinCode } from '../models/coincode';

@Component({
  selector: 'coincode-editor-component',
  templateUrl: 'coincode-editor.component.html',
  styleUrls: ['./coincode-editor.component.scss'],
  providers: []
})
export class CoincodeEditorComponent implements OnChanges{
  private _coinCodes: CoinCode[];

  constructor(private languageService: LanguageService) {
    this.coinCodes = []
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.json) {
      this.coinCodes = JSON.parse(this.json.replace(/'/gi, '"')); // initial data had json strings with single quotes. Please don't do this.
    }
    else {
      this.coinCodes = [];
    }
  }

  @Input('json') json: string;
  @Output() jsonChange = new EventEmitter<string>();

  get coinCodes() {
    return this._coinCodes;
  }

  set coinCodes(value: CoinCode[]) {
    this._coinCodes = value;
  }

  onDataChanged(e) {
    // devexpress datagrid insists on adding a KEY field if no primary key is designed, which also gets serialized. Annoying.
    var raw = this.coinCodes as any[];
    for (let i = 0; i < raw.length; i++) {
      raw[i].__KEY__ = undefined;
    }
    this.json = JSON.stringify(raw);
    this.jsonChange.emit(this.json);
  }
}
